.app-main-header {
  background-color: #ff5722 !important;
  color: #fff !important;
}
ul.nav-menu li.menu.open .sub-menu li > a.active,
.jr-card-thumb {
  /* background-color: #ff5722 !important;   */
  background-color: #4a863e;
}

.app-sidebar .side-nav ul.nav-menu li button,
.app-sidebar .side-nav ul.nav-menu li a {
  padding: 10px 20px 10px 20px;
  color: #000;
}
.app-logo-content.d-flex.align-items-center.justify-content-center {
  /* background-image: url('assets/images/background.jpg'); */
  background-color: #0a0a0a;
}
.MuiListItemIcon-root {
  min-width: 30px !important;
}
.app-sidebar .side-nav {
  background: #fff !important;
  box-shadow: 5px 10px 18px #ced4da;
}
div.mini-drawer .app-main-header.custom-color {
  background: #4a863e !important;
}

.app-sidebar ul.nav-menu li.menu.open > a,
.app-sidebar ul.nav-menu li a.active,
.cbutton:hover {
  background: #008000;
  color: #fff;
}

.theme-clr {
  color: #008000 !important;
}

.app-sidebar .side-nav ul.nav-menu li a:hover {
  background: #008000 !important;
  color: #fff;
}

ul.nav-menu li.menu .sub-menu li > a:hover,
ul.nav-menu li.menu .sub-menu li > a:focus,
ul.nav-menu li.menu.open .sub-menu li > a.active:before,
ul.nav-menu li.menu .sub-menu li > a:focus:before,
ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #4a863e !important;
  color: #000;
}
label.MuiInputLabel-shrink-200,
label.MuiInputLabel-focused-187 {
  color: #4a863e;
}
.page-heading i.zmdi-hc-fw {
  color: #4a863e;
}
.app-main-content .jr-btn,
button.CBmargin,
.app-login-form button {
  background-color: #4a863e !important;
  color: white;
}
.app-main-content .jr-btn:hover,
.app-login-form button:hover {
  background: #4a863e !important;
  text-decoration: none;
}
.app-container.fixed-drawer .app-main-header {
  background: #4a863e !important;
}

div#form-dialog-title {
  background: #4a863e;
}

/* Status labels*/
span.label {
  background: gray;
}

span.confirmed,
span.created {
  background: #ffcb00e0;
}

span.searching {
  background: #e0b91ffc;
}

span.started {
  background: #e0491f;
}

span.inrouteDrop {
  background: #b276e3;
}

span.inroutePick {
  background: #972ded;
}

span.completed,
span.Online,
span.active,
span.approved,
span.Yes,
span.yes,
span.success,
span.true {
  background: green;
}

span.cancelled,
span.cancel,
span.rejected,
span.blocked,
span.inactive,
span.No,
span.no,
span.false {
  background: #fd2121;
}
span.DestinationInroute,
span.PickupInroute,
span.inrouteDrop {
  background: #b276e3;
}

span.FindingTrips,
span.inroutePick,
span.FindingDrivers {
  background: #972ded;
}
span.Offline {
  background: #eb4d4d;
}

.uper-multi-row {
  background: #00000017;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 5px;
}
.react-time-picker {
  width: 100%;
  height: 32px;
  /* border-bottom: 1px solid #737373; */
}
.react-time-picker * {
  border: 0;
}
label.form-label {
  color: #737373;
  font-family: inherit;
  font-size: 16px;
  font-weight: 400;
}

.table-responsive-material .blog-profile .user-avatar img {
  height: 60px;
  width: 60px;
  object-fit: cover;
}

.user-profile.blog-profile .user-avatar {
  height: auto !important;
  width: auto !important;
}
.blog-detail-profile img {
  height: 120px;
  width: 120px;
}

.list {
  margin: 30px;
}
.item {
  height: 40px;
  width: 150px;
  box-shadow: 1px 1px 5px #ccc;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #f9593c;
  color: #fff;
  font-weight: 500;
}

.menulist .menuitem {
  height: 40px;
  width: 150px;
  box-shadow: 1px 1px 5px #ccc;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: green;
  color: #fff;
  font-weight: 500;
  margin: 20px;
}
.menulist .menuitem {
  margin-bottom: 20px;
}
.list .item:not(:last-child) {
  margin-bottom: 20px;
}
.vks .list {
  display: flex;
  gap: 30px;
}
.drag_drop h1 {
  padding: 10px;
  text-align: center;
  box-shadow: 1px 1px 5px #ccc;
  margin-top: 18px;
}
.no_data p {
  text-align: center;
  margin-top: 50px;
  font-weight: 500;
}

.customizeModal .modal-content {
  max-width: 650px;
}

.common-btn {
  height: 40px;
  background: #4a863e !important;
  color: #fff !important;
}

.menulist .menuitem {
  height: auto;
  min-height: 40px;
  padding: 10px;
  background-color: #707070;
  left: 0 !important;
}
.menulist .menuitem.dragging,
.menulist .menuitem.draggingOver {
  top: 0 !important;
  position: relative !important;
}
.menulist .menuitem.draggingOver {
  top: unset !important;
  /* bottom : 0 !important */
}
.InnerCol .item.dragging {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
}

.item {
  width: 100px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  /* Other styles */
  position: relative;
  z-index: 1; /* Adjust as needed */
  margin-bottom: 10px;
}

.menuitem {
  width: 100px; /* Adjust as needed */
  height: 50px;
  margin-bottom: 0px;
}
.dragging {
  z-index: 1000;
  cursor: grabbing;
  position: absolute;
  background-color: lightblue;
  opacity: 0.8;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.scrollable-container {
  overflow-y: auto;
}
.container {
  overflow: hidden;
}

.vks Wrpper {
  height: 50px;
}

.heading_timeslot li {
  list-style-type: none;
  width: 33.33%;
}

tr#monday-1 p,
tr#tuesday-2 p,
tr#wednesday-3 p,
tr#thursday-4 p,
tr#friday-5 p,
tr#saturday-6 p {
  display: none;
}

tr#sunday-0 p {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.Mui-checked + .MuiSwitch-track,
.Mui-checked .MuiSwitch-thumb {
  background-color: #4a863e !important;
}

.four-tack,
ul.location-addrees {
  margin-top: 30px;
}
.line {
  background: #202020;
  width: 100%;
}
.four-tack {
  display: flex;
  gap: 20px;
}
.fill-div {
  height: 5px;
  border-radius: 4px;
  /* background: linear-gradient(90.23deg, #ff0835 5.53%, #f16724 94.57%); */
  background: linear-gradient(90.23deg, #ff0835 5.53%, #4a863e 94.57%);
}

/* tab css */
.tabs {
  margin: 0 auto 0 0;
  font-size: 0;
}

.tabs > input[type="radio"] {
  display: none;
}

.tabs > div {
  display: none;
  border: 1px solid #e0e0e0;
  padding: 10px 15px;
  font-size: 16px;
}

#tab-btn-1:checked ~ #content-1,
#tab-btn-2:checked ~ #content-2,
#tab-btn-3:checked ~ #content-3 {
  display: block;
}

.tabs > label {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border-bottom: 3px solid transparent;
  /* background-color: #f5f5f5; */
  padding: 2px 8px;
  font-size: 16px;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  cursor: pointer;
  position: relative;
  top: 1px;
}

.tabs > label:not(:first-of-type) {
  border-left: none;
}

.tabs > input[type="radio"]:checked + label {
  background-color: #fff;
  border-bottom: 3px solid #4b8a45;
}

.gap-10 {
  gap: 10px !important;
}



.bird-eye-view-driver-list {
  height: 69%;
  overflow-y: scroll;
}

.bird-eye-view-driver-list::-webkit-scrollbar {
  width: 4px; /* Adjust the width of the scrollbar */
}

.bird-eye-view-driver-list::-webkit-scrollbar-thumb {
  background-color: green; /* Color of the scrollbar */
  border-radius: 4px;
}

.bird-eye-view-driver-list::-webkit-scrollbar-track {
  background-color: #f0f0f0; /* Optional: Change track background */
}

.dispatcher-list .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded{
  background-color: #dedad8;
  box-shadow: unset;
}

.dispatcher-list .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded > .col-12{
  padding: unset;
}

.dispatcher-list .MuiPaper-root.MuiPaper-elevation1.MuiPaper-rounded .p-0:first-child{
    background-color: #dedad8;
}
