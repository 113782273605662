/*Popover Styles*/
.popover {
  z-index: 1510;
}

.popover-header {
  background-color: transparent;
  border-bottom: 0 none;
  color: $black;
  color:#4A863E;
  font-size: $h2-font-size;
  padding: 15px 20px 0;
}

.popover-body {
  padding: 15px 20px;
}


